.menu {
  position: absolute;
  top: 32px;
  left: 32px;
  z-index: 5;
  width: 145px;
  padding: 4px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(44, 44, 44, 0.08);
  .item {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
    .text {
      color: #2f2f2f;
      font-weight: 400;
      font-size: 14px;
      font-family: 'Roboto';
    }
  }
}
