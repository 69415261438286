.item {
  overflow: hidden;
  .group {
    display: flex;
    align-items: center;
    height: 80px;
    margin: 8px 0;
    background: #fbfbfb;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    .icon {
      width: 32px;
      height: 32px;
      margin: 0 16px;
    }
    .text {
      flex: 1;
      .title {
        color: #2f2f2f;
        font-weight: 700;
        font-size: 14px;
        font-family: 'Roboto';
        line-height: 20px;
      }
      .desc {
        margin-top: 4px;
        color: #929292;
        font-weight: 400;
        font-size: 12px;
        font-family: 'Roboto';
        line-height: 16px;
      }
    }
    .badge {
      min-width: 6px;
      height: 16px;
      margin-right: 20px;
      padding: 0 5px;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 10px;
      color: #fff;
      line-height: 16px;
      text-align: center;
      background: #fa5a54;
      border: 1px solid #ffffff;
      border-radius: 100px;
    }
    .arrow {
      width: 16px;
      height: 16px;
      margin-right: 20px;
    }
  }
}
