.home {
  // min-height: 100vh;
  background: #fff;
  /* stylelint-disable */
  -webkit-touch-callout: none; /* 系统默认菜单被禁用 */
  user-select: none; /* webkit浏览器 */
  user-select: none; /* 早期浏览器 */
  user-select: none; /* 火狐 */
  user-select: none; /* IE10 */
  user-select: none;
  /* stylelint-enable */
  .contentWrapper {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    .baseNavBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 3;
      height: 56px;
      padding: 0 16px;
      background: #fff;
      .left {
        display: flex;
        align-items: center;
        position: relative;
        .avatar {
          width: 32px;
          height: 32px;
          margin-right: 16px;
          border-radius: 50%;
          object-fit: cover;
        }
        .title {
          font-family: 'Roboto';
          font-weight: 500;
          color: #2f2f2f;
          font-size: 18px;
        }
        .arrow {
          width: 24px;
          height: 24px;
          margin-left: 8px;
        }
      }
      .right {
        width: 24px;
        height: 24px;
      }
    }
    .content {
      margin-top: 80px;
      padding: 0 16px 16px;
      .title {
        padding: 12px 0;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #2f2f2f;
      }
    }
  }
}
