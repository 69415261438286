.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  &.open {
    z-index: 100;
    background: rgba(0, 0, 0, 0.7);
  }
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  circle {
    position: absolute;
  }
  .processLabel {
    position: absolute;
    left: 50%;
    top: 56%;
    transform: translateX(-50%) translateY(-50%);
    color: #FFF3E1;
    font-size: 14px;
    text-align: center;
  }
}