@mixin circle($diameter, $bg: linear-gradient(135deg, #ffcd22 0%, #ffb800 100%)) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $diameter;
  height: $diameter;
  background: $bg;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
  border-radius: 50%;
}

@keyframes loading {
  100%{
      stroke-dashoffset:0;
  }
}

.floatingBtn {
  .addBtn {
    position: fixed;
    right: 16px;
    bottom: 40px;
    z-index: 10;
    @include circle(56px);
  }
  .overlayContent {
    position: fixed;
    right: 16px;
    bottom: 40px;
    z-index: 1001;
    .item {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 4px;
      margin-bottom: 16px;
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
      .text {
        margin-right: 12px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
      }
      .btn {
        @include circle(48px);
      }
      .closeBtn {
        @include circle(56px, #fff);
      }
    }
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .uploadBtn {
    opacity: 0;
    height: 0;
  }
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  circle {
    position: absolute;
  }
  .mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }
  .mask.open {
    z-index: 100;
    background: rgba(0, 0, 0, 0.7);
  }
  .processLabel {
    position: absolute;
    left: 50%;
    top: 56%;
    transform: translateX(-50%) translateY(-50%);
    color: #FFF3E1;
    font-size: 14px;
    text-align: center;
  }
}
